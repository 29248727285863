import React from 'react'
import PropTypes from 'prop-types'
import pic03 from '../images/straw-greenhouse.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Mūsų braškės</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p className="text">
            Mūsų ūkyje auginamos aukščiausios kokybės braškės. Joms užauginti
            naudojami sertifikuoti Premium braškių daigai iš „Kaack
            Pflanzenvermehrung“ įmonės įsikūrusios Šiaurės Vokietijoje.
            Investuodami į daigus esame užtikrinti, kad augalas bus sveikas ir
            jam užauginti bus naudojamas minimalus trąšų kiekis. Braškėmis
            rūpinamės nuo jų pasodinimo iki jų atsiradimo ant Jūsų stalo.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">KONTAKTAI</h2>
          <p>Adomčiškių k., Dūkštų sen., Vilniaus raj.</p>
          <div>Telefonai:</div>
          <div>+370 623 61 825</div>
          <div>+370 631 93 472</div>
          <br />
          <a href="https://www.facebook.com/saldiuoga2019/" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/saldiuoga2019/
          </a>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
