import React from 'react'
import PropTypes from 'prop-types'
import strawLogo from '../images/straw.png';

const HEADER_TITLE = "Braškės";
const HEADER_TEXT = "Skaniausios braškės tiesiai iš ūkio";

const articles = [{
  id: 'intro',
  title: 'Mūsų braškės',
},{
  id: 'contact',
  title: 'Kontaktai'
}]

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
        <img src={strawLogo} alt="Braškės" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>{HEADER_TITLE}</h1>
        <p>{HEADER_TEXT}</p>
      </div>
    </div>
    <nav>
      <ul>
        {articles.map(({ id, title }) => (
          <li key={id}>
            <a
              href="javascript:;"
              onClick={() => {
                props.onOpenArticle(id)
              }}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
